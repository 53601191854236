<template>
  <a-modal
    class="attendance-dialog"
    width="1100px"
    v-model="visible"
    :title="title"
    @ok="visible = false"
  >
    <a-calendar v-model="date" value-format="YYYY-MM-DD" :validRange="validRange" class="table">
      <ul slot="dateCellRender" slot-scope="value" class="ul--list">
        <li
          v-for="(item,index) in handleGetValue(value)"
          :key="item.createTime"
          :style="{'color':item.status == 1 ? 'red':'black'}"
        >{{ index + 1 }}：{{ item.time }} <br> {{ item.location }}</li>
      </ul>
    </a-calendar>
  </a-modal>
</template>

<script>
import { employmentEmployInfo } from '@/api/recruitUse'
import moment from 'moment'
import { Calendar } from 'ant-design-vue'

export default {
  name: 'AttendanceDialog',
  components: {
    'a-calendar': Calendar
  },
  data () {
    return {
      visible: false,
      userId: null,
      demandId: null,
      date: moment().format('YYYY-MM-DD'),
      title: '',
      rangeData: []
    }
  },
  watch: {
    date: {
      handler (newValue, oldValue) {
        if (this.formatTime(newValue, 'YYYY-MM') !== this.formatTime(oldValue, 'YYYY-MM')) {
          this.handleGetRangeData(newValue, this.userId, this.demandId)
        }
      }
    }
  },
  computed: {
    validRange () {
      return [moment('2020-01-01'), moment()]
    }
  },
  methods: {
    init (user) {
      this.userId = user.userId
      this.demandId = user.demandId
      this.title = '考勤明细--' + user.realName
      this.visible = true
      this.date = moment().format('YYYY-MM-DD')
      this.handleGetRangeData(this.date, this.userId, this.demandId)
    },
    handleGetRangeData (date, userId, demandId) {
      const year = moment(this.date).format('YYYY')
      const month = moment(this.date).format('MM')
      this.rangeData = []
      employmentEmployInfo({
        month,
        year,
        userId,
        demandId
      }).then(res => {
        this.rangeData = res.data || []
        // if (res.data.length) {
        //   const data = res.data.reduce((data, item) => {
        //     return {
        //       ...item,
        //       [`time${item.day}`]: item.time
        //     }
        //   }, {})
        //   this.modelTableData = [data]
        // } else {
        //   this.modelTableData = []
        // }
      })
    },
    handleGetValue (date) {
      const year = moment(date).format('YYYY')
      const day = moment(date).format('DD')
      const month = moment(date).format('MM')
      const dataList = this.rangeData.filter(item => item.day === day && item.month === month && item.year === year)
      return dataList
    }
  }
}
</script>

<style lang="less">
.attendance-dialog {
  .table {
    .ant-fullcalendar-header {
      .ant-radio-group {
        display: none !important;
      }
    }
  }
}
</style>
